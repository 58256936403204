import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox", "counter"];
  static values = {
    max: Number
  };

  connect() {
    this.handleCounter();
    this.formTarget.addEventListener("change", this.handleCounter.bind(this));
  }

  handleCounter() {
    const counter = this.checkboxTargets.reduce((count, checkbox) => checkbox.checked ? count + 1 : count, 0);
    this.counterTarget.innerText = this.maxValue - counter;

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.disabled = counter === this.maxValue && !checkbox.checked;
    });
  }
}
